.form-block {
	h4 {
		margin-bottom: 20px;
	}

	&:not(:last-child) {
		padding-bottom: 30px;
		border-bottom: 1px solid $border-color-ligher;
		margin-bottom: 30px;
	}

	.buttons {
		@include flexbox();
		@include justify-content(space-between);
		@include align-items(center);

		button {
			&:not(:last-child) {
				margin-right: 8px;
			}
		}
	}

	.header {
		@include media-below(sm) {
			margin-bottom: 30px;
		}
	}
}

.form-box-content {
	background-color: $background-gray-light;
	padding: 16px;
	@include border-radius(5px);
	width: 100%;

	h5 {
		margin-bottom: 16px;
		font-size: 14px;
		line-height: 18px;
		font-weight: $font-semibold;
	}

	dl {
		&:not(:last-child) {
			margin-bottom: 15px;
		}

		dt {
			font-size: 12px;
			line-height: 15px;
		}

		dd {
			font-size: 14px;
			line-height: 26px;
		}
 	}

	img {
		margin: 20px 0;
	}

	& + .form-box-content {
		margin-top: 16px;
	}
}

.default-spacing {
	margin-bottom: 16px;

	&.above {
		margin-top: 16px;
	}

	&.x2 {
		margin-bottom: 32px;

		&.above {
			margin-top: 32px;
		}
	}
}

.form-group, .form-group-floating {
	label {
    font-weight: $font-regular;
    font-size: 14px;
  }

	.form-error {
		font-size: 11px;
		color: $color-error;
	}
}

.form-group-floating {
	position: relative;
	height: 50px;
	border: 1px solid $border-color-light;
  background-color: transparent;
	@include border-radius(5px);
	width: 100%;
	@include transition(all, .3s);

	&.border-error {
		border-color: $color-error;
	}

	.svg-icon-before-label {
		svg {
			position: absolute;
			bottom: -3px;
			right: -5px;
			width: 10px;
			height: 10px;
		}
	}

	&.with-suffix {
		@include flexbox();
		padding-right: 10px;

		.suffix {
			height: 100%;
			@include flexbox();
			@include align-items(center);
			font-size: 14px;
		}
	}

	label {
    line-height: 20px;
    padding-left: 20px;
    margin-bottom: 5px;

    + select, + input[type="text"], + input[type="password"], + textarea, + .form-item, + .input-label-text {
      margin-top: 3px;
    }
  }

	.form-error {
		position: absolute;
		right: 5px;
		top: 5px;
	}

	label {
		position: absolute;
		height: 12px;
		top: 15px;
		left: 0;
		padding-left: 16px;
		color: $text-gray-light;
		@include transition(top, .15s);
		cursor: pointer;
	}

	input[type=text], input[type=password], textarea, select {
		position: relative;
		font-size: 14px;
		height: 20px;
		font-weight: $font-regular;
		padding: 0 16px;
		top: 25px;
		@include transition(top, .15s);
		border: 0;

		&:focus, &.isvalue {
			top: 24px;
			color: $text-gray!important;

			+ label {
				font-size: 12px;
				top: 5px;
				color: $text-gray-light;
			}
		}
	}

	select {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		background-image: url('#{$icon-path}chevron-down-regular-dropdown.svg');
		background-repeat: no-repeat;
		background-position: 97% 0;
		background-size: 12px 7px;
	}

	&.txtarea {
    height: 150px;

    textarea {
      height: 90%;
    }
  }
}

.checkbox-label {
	display: block;
	position: relative;
	padding-left: 35px!important;
	margin-bottom: 12px;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	margin-bottom: 0!important;
  text-align: left;
  line-height: 20px!important;

	input {
	  position: absolute;
	  opacity: 0;
	  cursor: pointer;
	  height: 0;
	  width: 0;
	}

	.checkmark {
	  position: absolute;
	  top: 0;
	  left: 0;
	  height: 20px;
	  width: 20px;
		@include border-radius(4px);
		border: 1px solid $black;
	}

	input:checked ~ .checkmark {
	  background-color: $color-primary;
	}

	.checkmark:after {
	  content: "";
	  position: absolute;
	  display: none;
	}

	input:checked ~ .checkmark:after {
	  display: block;
	}

	.checkmark:after {
		left: 7px;
    top: 3px;
    width: 5px;
    height: 10px;
	  border: solid white;
	  border-width: 0 3px 3px 0;
	  -webkit-transform: rotate(45deg);
	  -ms-transform: rotate(45deg);
	  transform: rotate(45deg);
	}

	+ .form-error {
		padding-left: 35px;
	}
}

.radio-label {
  @include flexbox();
	@include align-items(center);
	cursor: pointer;

  input[type="radio"] {
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
		min-width: 20px;
		min-height: 20px;
    border: 2px solid $color-primary;
    margin: 0;
    outline: none;
    border-radius: 2px;
    margin-right: 10px;
    border-radius: 50%;
    position: relative;

    &:checked, &.selected {
      border-color: $color-primary;

      &:before {
        content: "";
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: $color-primary;
        position: absolute;
        left: 3px;
        top: 3px;
      }
    }
  }

	&.reverse {
		@include justify-content(space-between);

		input[type="radio"] {
			order: 2;
		}
	}
}

.form-disclaimer {
	font-size: 11px;
	font-style: italic;
	line-height: 150%;
}
