// == Bootstrap

.lessBetweenGutter, .gutter-10 {
	@include media-above(sm) {
		[class*="col"] {
			&:not(:last-child):not(:first-child) {
				padding-left: 10px;
				padding-right: 10px;
			}

			&:last-child {
				padding-left: 10px;
			}

			&:first-child {
				padding-right: 10px;
			}
		}
	}
}

.gutter-5 {
	@include media-above(sm) {
		[class*="col"] {
			&:not(:last-child):not(:first-child) {
				padding-left: 5px;
				padding-right: 5px;
			}

			&:last-child {
				padding-left: 5px;
			}

			&:first-child {
				padding-right: 5px;
			}
		}
	}
}

// == Datetimepicker

[class="react-datepicker__tab-loop"] {
	@include order(3);
}

// == Multi select

.multi-select {
  --rmsc-main: #4285f4;
  --rmsc-hover: #f1f3f5;
  --rmsc-selected: #e2e6ea;
  --rmsc-border: #C8D2DD!important; // same as: $border-color-light
  --rmsc-gray: #8898AA!important; // same as: $text-gray-light
  --rmsc-bg: #fff;
  --rmsc-p: 10px; /* Spacing */
  --rmsc-radius: 5px!important; /* Radius */
  --rmsc-h: 40px; /* Height */
}

// == FSlightbox

.fslightbox-toolbar {
	background: transparent!important;
	top: 10px!important;

	.fslightbox-toolbar-button {
		display: none;

		&:last-child {
			display: block;
		}
	}
}

// == Google maps API

.google-map-wrapper {
	min-width: 700px;
	width: 100%;
	height: 500px;

	@include media-below(lg) {
		min-width: 500px;
	}

	@include media-below(xs) {
		min-width: 340px;
		height: 250px;
	}
}

// == Cookie consent component

.CookieConsent {
	position: fixed;
	left: auto;
	right: 30px;
	bottom: 30px!important;
	font-size: 11px;
	@include border-radius(8px);
	padding: 10px;
	background-color: #565656f2;
	color: $white;
	z-index: 15;
	max-width: 200px;
	line-height: 150%;

	@include media-below(sm) {
		left: 0;
		right: 0;
		margin: auto;
		bottom: 80px!important;
		max-width: 300px;
		height: 130px;
	}

	div {
		width: 100%;

		a {
			color: inherit;
			display: inline-block;
			margin-left: 5px;
			text-decoration: underline;
		}

		&:first-child {
			display: block;
			margin: 0;
		}

		&:last-child {
			margin-top: 15px;
			@include flexbox();
			@include justify-content(center);

			button {
				margin: 0;
				background-color: $primary-color;
				border: 0;
				cursor: pointer;;
				display: block;
				color: $white;
				padding: 5px;
				width: 90%;
			}
		}
	}
}
