// == Default

input[type=text], input[type=password], input[type=email], input[type="time"], [type="date"], textarea, select {
	border: 0;
	background-color: transparent;
	@include border-radius(5px);
	padding: 10px 15px;
	// color: $gray-dark;
	font-size: 14px;
	width: 100%;
	font-weight: $font-regular;
	color: $text-gray-light!important;

	@include media-below(sm) {
		font-size: 14px!important;
	}

	&:disabled {
		@include opacity(0.5);
	}

	&.border {
		border: 1px solid $section-separator-color;
	}
}

input[type=text], input[type=password], input[type=email], input[type="time"], [type="date"], textarea, select {
	&:disabled {
		@include opacity(0.5);
	}
}

input[type="time"]::-webkit-inner-spin-button,
input[type="time"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}

textarea {
	min-height: 150px;
	resize: none;
}

input:focus ~ .floatingLabel,
input:not(.empty) ~ .floatingLabel {
	top: 3px;
	bottom: 0px;
	left: 15px;
	font-size: 11px;
	opacity: 1;
}

.floatingLabel {
  position: absolute;
  pointer-events: none;
  left: 15px;
  top: 16px;
	@include transition(all, .2s, ease);
	color: $text-gray;
	font-size: 15px;
}

.borderedFormGroup {
	.formGroup {
		border-bottom: 1px solid $section-separator-color;
		padding: 10px 0;

		&:first-child {
			border-top: 1px solid $section-separator-color;
		}
	}

	&.noBorderStart {
		.formGroup:first-child {
			border-top: 0;
		}
	}

	&.noBorderEnd {
		.formGroup:last-child {
			border-bottom: 0;
		}
	}
}

.formBlock {
	&:not(:first-child) {
		margin-top: 30px;
	}
}

.formGroup {
	label.formItemLabel {
		position: relative;;
		width: 100%;
		padding-top: 15px;
		border: 1px solid $border-color-light;
		@include border-radius(5px);

		&.disabled {
			@include opacity(0.5);
		}

		input[type=text], input[type=password], input[type=email] {
			padding: 8px 15px;
		}

		&:not(.isFloating) {
			input[type=text], input[type=password], input[type=email] {
				height: 50px;
			}
		}

		&.icon, &.suffix, &.prefix {
			@include flexbox();
			@include justify-content(space-between);
			@include align-items(center);

			i {
				margin-right: 15px;
			}

			&.left {
				> *:first-child {
					@include order(2);
				}

				i {
					@include order(1);
					margin: 0 0 0 15px;
				}
			}
		}

		&.prefix {
			.inputPrefix {
				padding-left: 15px;
				min-width: 70px;
				display: block;
				font-size: 14px;
				font-weight: $font-semibold;
			}
		}

		&.suffix {
			border: 0;
			font-weight: $font-medium;

			input[type=text], input[type=password], input[type=email], select {
				border: 1px solid $border-color-light;
				@include border-radius(5px);
				margin-right: 10px;
			}
		}
	}

	&:not(:last-child) {
		margin-bottom: 10px;
	}

	&.pushDown {
		margin-bottom: 15px;
	}

	&.pushUp {
		margin-top: 15px;
	}

	&.split, > .split {
		@include flexbox();
		@include justify-content(space-between);

		@include media-below(xxs) {
			@include flex-direction(column);
		}

		> * + * {
			@include media-below(xxs) {
				margin-top: 10px;
			}
		}

		> * {
			width: 48.5%!important;

			@include media-below(xxs) {
				width: 100%!important;
			}
		}
	}

	&.w65 {
		width: 65%;
	}

	&.toBoundary, &.toBoundaryFlexy {
		@include flexbox();
		@include justify-content(space-between);
	}

	&.toBoundary {
		> * {
			width: 50%;

			&:last-child {
				text-align: right;
			}
		}
	}

	&.toBoundaryFlexy {
		> * {
			&:last-child {
				text-align: right;
			}
		}
	}	

	&.inlineList {
		@include flexbox();
		
		>:not(:last-child) {
			margin-right: 10px;
		}
	}

	.fieldDisclaimer {
		font-size: 11px;
		line-height: 130%;
	}
}

.error {
	color: $danger-color;
	display: block;
	width: 100%;
	font-size: 12px;
	margin-top: 5px;
}

// Select/dropdown

.multi-select {
	min-width: 170px;
	max-width: 200px;
	cursor: pointer;

	&.disabled {
		@include opacity(0.5);
	}

	[class*="dropdown-heading"] {
		padding: 0;

		* {
			transition: none;
			-webkit-transition: none;
		}
	}

	[class*="dropdown-heading-value"] {
		cursor: pointer;
		padding: 0 20px;
		max-width: 95%;
	}

	.gray {
		font-size: 14px;
	}

	svg {
		display: none;
	}

	.dropdown-container {
		background-image: url('#{$icon-path}chevron-down-regular-dropdown.svg');
		background-repeat: no-repeat;
		background-position: calc(100% - 20px);
		background-size: 12px 7px;

		&:focus-within {
			border-color: inherit;
			box-shadow: none;
		}

		.dropdown-heading {
			min-height: 40px;
		}
	}
}

.select {
	min-width: 100px;
	color: $text-gray;

	&.box {
		@include flexbox();
		@include align-items(center);
	}

	&.disabled {
		@include opacity(0.5);
	}

	[class*="control"] {
		transition: none;
		-webkit-transition: none;
		@include border-radius(5px);
		// color: $gray-dark;
		font-size: 14px;
		width: 100%;
		padding: 0 20px;
		font-weight: $font-regular;
		color: $text-gray-light!important;
		min-height: 42px;
		border: 0;
		box-shadow: none;

		&:hover {
			border-color: none;
		}
	}

	[class*="placeholder"] {
		color: $text-gray-light;
	}

	[class*="indicatorSeparator"] {
		display: none;
	}

	[class*="ValueContainer"] {
		padding: 0;
	}

	[class*="indicatorContainer"] {
		padding: 0;

		&:after {
			content: "";
			@include icon('chevron-down-regular-dropdown.svg', 12, 7);
		}
	}

	[class*="menu"] {
		@include media-below(md) {
			text-align: left;
		}
	}

	svg {
		display: none;
	}
}

select, .multi-select .gray {
	border: 0;
	width: 100%;
	line-height: 18px;
}

select {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background-image: url('#{$icon-path}chevron-down-regular-dropdown.svg');
	background-repeat: no-repeat;
	background-position: 97%;
	background-size: 12px 7px;
	cursor: pointer;

	button {
		width: 10%;
	}
}

select, .select {
	&.box {
		border: 1px solid $border-color-light;
		@include border-radius(5px);
		width: 100%;
		height: 50px;
		color: $gray-dark;
	}
}

// Checkbox

.checkboxList {
	@include flexbox();
	@include flex-wrap(wrap);

	> * {
		&:not(:last-child) {
			margin-bottom: 15px;
		}
	}

	&:not(.singleCol) {
		> * {
			width: 50%!important;
		}
	}
}

@mixin checkbox() {
  position: relative;
  cursor: pointer;
  color: $text-gray;

  input {
	  position: absolute;
	  opacity: 0;
	  height: 0;
	  width: 0;
	}
}

.checkbox, .radiobutton {
	position: relative;
	cursor: pointer;
	@include flexbox();
	@include align-items(center);

	&.disabled {
		@include opacity(0.5);
	}

	&.textLeft {
		.input {
			@include order(2);
		}
	}

	&.toBoundary {
		@include justify-content(space-between);
	}

	[class*="loader"] {
		margin-left: 15px;
	}

	&.default, &.big, &.solid, &.dark {
		.input {
		  @include checkbox();
			flex: 0 0 20px;
			max-width: 20px;
			margin-right: 10px;
			@include flexbox();
			@include align-items(center);

			&.disabled {
				@include opacity(0.5);
			}

			@include media-below(md) {
				margin-right: 15px;
			}
		}

		span.checkmark {
			content: '';
			position: absolute;
			@include icon('square-light.svg', 20, 20);

			@include media-below(md) {
				@include icon-dimension-only(24, 24);
			}
		}

		input:checked ~ .checkmark {
			@include icon-image-only('check-square-light.svg');
		}
	}

	&.solid {
		input:checked ~ .checkmark {
			@include icon-image-only('check-square-selected.svg');
		}
	}

	&.toggle {
		.input {
			height: 30px;
		  width: 45px;
			min-width: 45px;
		  background: $checkbox-toggle-background;
		  @include border-radius(100px);
		  @include checkbox();
		  @include flexbox();

			&:not(.disabled).active {
				background: $primary-color;
			}
		}

		span.checkmark {
			content: '';
		  position: absolute;
		  left: 4px;
		  top: 3px;
		  display: block;
		  width: 23px;
		  height: 23px;
			@include border-radius(100px);
			@include box-shadow(0px, 3px, 3px, rgba(0,0,0,0.05));
			background-color: $white;
			@include transition(all, 0.3s, ease);
		}

		input:checked ~ .checkmark {
			left: 18px;
		}

		&.small {
			.input {
				margin-right: 5px;
				width: 30px;
				min-width: 30px;
				height: 20px;

				span.checkmark {
					width: 15px;
					height: 15px;
					top: 2.2px;
					left: 4px;
				}

				input:checked ~ .checkmark {
					left: 12px;
				}
			}
		}

		&.labelLeft {
			.input {
				@include order(2);
				margin-left: 5px;
			}
		}
	}
}

// Radiobutton

.radiobutton {
	&.default, &.big, &.dark {
		span.checkmark {
			content: '';
			position: absolute;
			background: none;
			width: 20px;
			height: 20px;
			@include border-radius(50%);
			border: 1px solid $border-color-light;

			@include media-below(md) {
				width: 24px;
				height: 24px;
			}
		}

		input:checked ~ .checkmark {
			background: none;

			&:after {
				content: "";
				position: absolute;
				display: block;
				width: 12px;
				height: 12px;
				background: $primary-color;
				@include border-radius(50%);
				left: 0;
				top: 0;
				bottom: 0;
				right: 0;
				margin: auto;
			}
		}
	}

	&.dark {
		input:checked ~ .checkmark {
			&:after {
				background-color: $black;
			}
		}
	}

	&.big {
		width: 100%;
		@include justify-content(space-between);
		padding: 40px;
		border: 1px solid $border-color-light;
		color: $gray-dark;
		@include border-radius(5px);
		@include transition(all, .3s, ease-in-out);

		span {
			width: 50%;
			text-align: right;
		}

		&:not(:last-child) {
			margin-bottom: 20px;
		}

		&.active {
			border-color: $gray-dark;
		}

		.input {
			@include order(2);
		}
	}
}

// Slider input

.sliderInput {

	&:not(:last-child) {
		margin-bottom: 30px;
	}

	header {
		@include flexbox();
		@include justify-content(flex-start);
		@include align-items(center);

		span {
			margin-top: 10px;
			font-size: 15px;
			line-height: 24px;

			@include phone() {
				max-width: 170px;
			}

			p {
				font-size: 12px;
				line-height: 24px;
			}
		}

		.input-label {
			margin-left: auto;
			background-color: $input-background-color;
			@include border-radius(5px);
			min-width: 125px;

			input[type=text] {
				background-color: $input-background-color;
				text-align: center;
				width: 100px;

				&.sm {
					width: 60px;
				}
			}

			> span {
				padding-right: 10px;
			}
		}
	}

	.slider {
		margin: 0;

		[class*="rc-slider-rail"] {
			background-color: #EFF4FB;
			height: 5px;
			width: 100%;
		}

		[class*="rc-slider-track"] {
			background-color: $primary-color;
		}

		[class*="rc-slider-handle"] {
			width: 27px;
			height: 27px;
			background-color: #6872E3;
			border: 6px solid #fff;
			box-shadow: 0px 0px 20px #525F7F29;
			@include border-radius(50%);
			margin-top: -10px;
		}

		[class*="rc-slider-disabled"] {
			background-color: transparent;

			[class*="rc-slider-handle"] {
				background-color: $slider-handle-background;
			}

			[class*="rc-slider-track"] {
				background-color: $slider-handle-background;
			}
		}
	}

	.rangeInputs {
		@include flexbox();
		@include justify-content(space-between);
		@include align-items(center);
		margin-top: 25px;

		> * {
			width: 45%;
		}

		span {
			width: 10%;
			position: relative;

			&:before {
				content: '';
				position: absolute;
				width: 10px;
				height: 1px;
				background-color: $border-color-light;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				margin: auto;
			}
		}
	}
}


// Upload (drag and drop / button) zone

.uploadZone {
	width: 100%;
	color: $text-gray-light;

	&:not(:last-child) {
		margin-bottom: 40px;
	}

	> h5, > p {
		font-size: 14px;
		line-height: 18px;
		font-weight: $font-semibold;
		color: $gray-dark;

		& + p {
			margin: 8px 0 12px 0;
			text-align: left;
			color: $text-gray-light;
			font-weight: $font-regular;
			font-size: 13px;
		}
	}

	.dragDrop {
		@include justify-content(center);
		@include flexbox();
		@include flex-direction(column);
		@include align-items(center);
		@include border-radius(4px);
		width: 100%;
		margin-top: 8px;
		border: 1px dashed $border-color-light;
		padding: 24px;

		label {
			cursor: pointer;

			svg {
				margin-bottom: 5px;
			}
		}
	}

	svg + p {
		margin-top: 10px;
		font-weight: $font-medium;
	}

	p {
		text-align: center;
		font-size: 14px;
		line-height: 21px;
		margin: 0;
		color: $gray-dark;

		label {
			color: $color-secondary;
			cursor: pointer;
		}

		&.formats {
			font-size: 12px;
			line-height: 16px;
			margin-top: 4px;
		}
	}

	.targetOverlay {
		position: absolute;
		@include flexbox();
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(255,255,255,0.5);
		z-index: 10;
		@include align-items(center);
		@include justify-content(center);
		color: $primary-color;
	}
}

.uploadList {
	padding: 5px;
	width: 100%;
	margin-top: 20px;

	&:empty {
		margin: 0;
		padding: 0;
	}

	> *:not(:last-of-type) {
		margin-bottom: 15px;
	}
}

.uploadedList {
	.box {
		padding-bottom: 10px;
		position: relative;
		@include flexbox();
		@include align-items(center);
		@include justify-content(spece-between);
		background-color: $white;
		width: 100%;

		&:first-child {
			padding-top: 20px;
		}

		.actions {
			@include flexbox();
			@include align-items(center);

			button, a {
				display: block;
				width: 32px;
				height: 32px;
				@include flexbox();
				@include align-items(center);
				@include justify-content(center);
				@include border-radius(4px);

				&:not(:last-child) {
					margin-right: 10px;
				}

				&:hover {
					background-color: $tag-light-background;
				}
			}
		}

		.filename {
			overflow-wrap: anywhere;
			font-size: 14px;
			line-height: 21px;
			display: block;
			width: 100%;
			color: $gray-dark;
			position: relative;
			padding-left: 30px;

			img {
				height: 26px;
			}

			svg, img {
				position: absolute;
				left: 0;
				top: 3px;
				max-width: 25px;
				height: auto;
			}
		}
	}
}

.fileName {
	position: relative;
	margin: 5px 0;
	display: block;
	max-width: 100%;
	word-wrap: break-word;

	&:hover {
		.abort {
			display: block;
		}
	}
}

.progressBar {
	width: 100%;
	background-color: $border-color-light;
	border: 0;
	@include border-radius(5px);
	height: 5px;

	> span {
			display: block;
			width: 50%;
			background-color: $primary-color;
			height: 100%;
			@include border-radius(5px);
	}
}

.abort {
	position: absolute;
	top: 0px;
	right: 0;
	color: $danger-color;
	display: none;
	font-size: 12px;
}

.uploadStatus {
	&.error, &.aborted {
		color: $danger-color;

		.progressBar {
			span {
				background-color: $danger-color;
			}
		}
	}

	&.done {
		color: $success-color;

		.progressBar {
			span {
				background-color: $success-color;
			}
		}
	}
}

.errorMessage {
	display: inline-block;
	margin-top: 5px;
	color: $danger-color;
}

.uploadButton {
	width: auto!important;

	input {
		visibility: hidden;
		position: absolute;;
		width: 0;
		left: 0;
		top: 0;
	}
}
