.icon {
	display: inline-block;
	position: relative;

	&.blank-12 {
		width: 12px;
		height: 12px;
	}

	&.blank-16 {
		width: 16px;
		height: 16px;
	}

	&.r180 {
		@include rotate(180);
	}

	&.search { @include icon('search-white.svg', 16, 16); }
	&.dropdown-arrow-down {
		@include icon('chevron-down-regular.svg', 8, 4.5);

		&.white { @include icon-image-only('chevron-down-regular-white.svg'); }
	}

	&.flag-slovenia { @include background-image('/img/flag-slovenia.svg', 16, 16); }
	&.flag-croatia { @include background-image('/img/flag-croatia.svg', 16, 16); }

	&.telescope { @include icon('telescope-light.svg', 50, 40); }
	&.angel { @include icon('angel-light.svg', 45, 40); }
	&.lamp-desk { @include icon('lamp-desk-light.svg', 40, 40); }

	&.plus {
		@include icon('plus-regular.svg', 16, 16);
		&.primary { @include icon-image-only('plus-regular-primary.svg'); }
		&.sm { @include icon-dimension-only(12, 12); }
	}

	&.plus-v2 { @include icon('plus-regular-v2.svg', 12, 12); }

	&.plus-circle {
		&.primary { @include icon('plus-circle-primary.svg', 20, 20); }
	}

	&.control-arrow-left { @include icon('chevron-left-regular.svg', 7, 12); }
	&.control-arrow-right { @include icon('chevron-right-regular.svg', 7, 12); }

	&.facebook {
		@include icon('facebook.svg', 10, 20);
		&.white { @include icon-image-only('facebook-f-white.svg'); }
	}
	&.instagram { @include icon('instagram.svg', 20, 20); }
	&.google { @include icon('logo-google.svg', 18, 18); }

	&.arrow-right-primary { @include icon('arrow-right-primary.svg', 12, 12); }
	&.arrow-right {
		&.mk { @include icon('chevron-right-mk.svg', 7, 12); }
		&.white { @include icon('chevron-right-white.svg', 14, 14); }
	}

	&.arrow-right-chevron {
		&.white { @include icon('chevron-right-regular-white.svg', 7, 13); }
	}

	&.chevron-circle-right {
		@include icon('chevron-circle-right-solid.svg', 12, 12);
	}

	&.arrow-down {
		@include icon('chevron-down-solid.svg', 10, 6);
		&.primary { @include icon-image-only('chevron-down-primary.svg'); }
	}
	&.arrow-left {
		@include icon('arrow-left-regular.svg', 14, 14);
	}

	&.filters {
		@include icon('sliders-v-square.svg', 14, 12);
		&.light { @include icon-image-only('sliders-v-square-light.svg'); }
	}
	&.layout-list {
		@include icon('th-list.svg', 23, 20);
		&.light { @include icon-image-only('th-list-light.svg'); }
	}
	&.layout-grid {
		@include icon('th.svg', 23, 20);
		&.light { @include icon-image-only('th-light.svg'); }
	}

	&.times {
		@include icon('times-regular-dark.svg', 12, 12);
		&.sm { @include icon-dimension-only(8, 8); }
		&.light { @include icon-image-only('times-regular-light.svg'); }
		&.white { @include icon-image-only('times-regular-white.svg'); }
	}

	&.gallery { @include icon('camera-solid.svg', 16, 14); }
	&.tloris { @include icon('ruler-triangle-solid.svg', 16, 16); }
	&.map {
		@include icon('map-marker-alt-solid.svg', 16, 16);
		&.light { @include icon-image-only('map-marker-alt-light.svg'); }
		&.white { @include icon-image-only('map-marker-alt-solid-white.svg'); }
	}
	&.street-view { @include icon('street-view-solid.svg', 16, 16); }
	&.bookmark { @include icon('bookmark-regular-light.svg', 12, 16); }
	&.share-content { @include icon('share-alt-regular-light.svg', 14, 16); }
	&.print { @include icon('print-regular-light.svg', 16, 16); }

	&.stopwatch {
		@include icon('stopwatch-light.svg', 16, 16);
		&.danger { @include icon-image-only('stopwatch-danger.svg'); }
	}
	&.fire {
		&.success { @include icon('fire-solid-success.svg', 12, 16); }
	}
	&.calendar { @include icon('calendar-day-light.svg', 16, 16); }
	&.video-play { @include icon('video-play.svg', 16, 16); }
	&.clock { @include icon('clock-light.svg', 16, 16); }
	&.sack { @include icon('sack-light.svg', 16, 16); }
	&.contact { @include icon('phone-volume-light.svg', 16, 16); }
	&.lock {
		@include icon('lock-alt-solid.svg', 14, 16);
		&.white { @include icon-image-only('lock-alt-solid-white.svg') }
	}

	&.lock-alt {
		&.white { @include icon('lock-alt-regular-white.svg', 10, 12); }
	}

	&.info {
		@include icon('exclamation-circle-solid.svg', 16, 16);

		&.white { @include icon-image-only('exclamation-circle-solid-white.svg'); }
		&.red { @include icon-image-only('exclamation-circle-solid-red.svg'); }
	}
	&.share { @include icon('delez-icon.svg', 20, 21); }
	&.sign {
		@include icon('sign-light.svg', 20, 20);
		&.regular { @include icon-image-only('sign-regular.svg'); }
		&.md { @include icon-dimension-only(14, 14); }
	}
	&.li-bullet { @include icon('check-regular.svg', 12, 12); }
	&.li-check { @include icon('check-regular.svg', 15, 12); }

	&.check-circle-light {
		&.primary { @include icon('check-circle-light-primary.svg', 20, 20); }
	}

	&.download { @include icon('arrow-to-bottom-regular.svg', 14, 16); }
	&.external-link { @include icon('external-link-regular.svg', 16, 16); }

	&.bell {
		@include icon('bell-regular.svg', 17, 20);
		&.white { @include icon-image-only('bell-regular-white.svg'); }
		&.primary { @include icon-image-only('bell-regular-primary.svg'); }
		&.light { @include icon('bell-regular-light.svg', 16, 16); }
	}

	&.bell-on {
		&.white { @include icon('bell-on-solid.svg', 17, 20); }
		&.primary { @include icon('bell-on-primary.svg', 24, 20); }
		&.sm { @include icon-dimension-only(15, 12); }
	}

	&.heart {
		 @include icon('heart-regular.svg', 16, 16);
		 &.light { @include icon-image-only('heart-light.svg'); }
		 &.solid { @include icon-image-only('heart-solid.svg'); }
		 &.white { @include icon-image-only('heart-white.svg'); }
	}

	&.heart-solid {
		 @include icon('heart-solid-light.svg', 20, 20);
		 &.light { @include icon-image-only('heart-solid-light.svg'); }
		 &.primary { @include icon-image-only('heart-solid-primary.svg'); }
	}

	&.user { @include icon('user-regular.svg', 12, 14); }
	&.pen { @include icon('pen-solid.svg', 12, 12); }
	&.coq { @include icon('cog-regular.svg', 14, 14); }
	&.upload { @include icon('cloud-upload-solid.svg', 30, 21); }

	&.home {
		@include icon('home.svg', 20, 20);
		&.primary { @include icon-image-only('home-primary.svg'); }
	}

	&.profile {
		@include icon('profile.svg', 20, 20);
		&.primary { @include icon-image-only('profile-primary.svg'); }
	}

	&.magnifier {
		@include icon('magnifier.svg', 20, 20);
		&.primary { @include icon-image-only('magnifier-primary.svg'); }
	}

	&.save {
		@include icon('save-solid.svg', 20, 20);
		&.primary { @include icon-image-only('save-solid-primary.svg'); }
	}

	&.analytics {
		&.special { @include icon('analytics-special.svg', 50, 50); }
	}

	&.sold {
		&.special { @include icon('sold-special.svg', 50, 50); }
	}

	&.gavel {
		&.special { @include icon('gavel-special.svg', 50, 50); }
	}

	&.chart-network {
			&.special { @include icon('chart-network-regular.svg', 25, 20); }
	}

	&.auction-offer {
		@include icon('auction-offer.svg', 16, 16);
	}

	&.auction-sequence {
		@include icon('auction-sequence.svg', 16, 16);
	}

	&.sand-clock {
		@include icon('sand-clock.svg', 16, 16);
	}

	&.trash {
		@include icon('trash-alt-regular.svg', 14, 16);
	}

	&.payment {
		&.paypal { @include icon('paypal.svg', 64, 24); }
		&.visa { @include icon('visa.svg', 38, 24); }
		&.mastercard { @include icon('mastercard.svg', 38, 24); }
	}
}
