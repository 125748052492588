.callout {
	width: 100%;
	padding: 16px;
	text-align: center;
	@include border-radius(4px);

	&.flex {
		text-align: left;
	}

	&.aligned {
		@include flexbox();
		@include align-items(center);
		@include justify-content(center);

		@include media-below(sm) {
			@include flex-direction(column);

			> a, div, button {
				margin-top: 10px;
			}

			svg, span {
				margin-bottom: 10px;
			}
		}
	}

	a, button {
		color: inherit;
		text-decoration: underline;
		font-weight: inherit;
	}

	&.medium {
		font-size: 14px;
		font-weight: $font-medium;
	}

	&.compact {
		padding: 10px 20px;
	}

	&:not(.outline) {
		background-color: $background-gray;

		&.dangerlight {
			background-color: $background-error-light;
			color: $color-error-light;
		}

		&.danger {
			background-color: $danger-color;
			color: $white;
		}

		&.success {
			background-color: $success-color;
			color: $white;
		}

		&.successlight {
			background-color: $background-success-light;
			color: $color-success-light;
		}

		&.dark {
			background-color: $background-darkgray;
			color: $white
		}

		&.warning {
			background-color: $background-warning;
			color: $color-warning;
		}
	}

	&.outline {
		border: 1px solid transparent;
		padding: 15px;

		&.danger {
			border-color: $danger-color;
			color: $danger-color;
		}

		&.success {
			border-color: $success-color;
			color: $success-color;
		}
	}

	i, svg {
		margin-right: 10px;
	}
}
